<template>
  <div id="app" v-cloak>
    <loading></loading>
    <layout-body></layout-body>
    <div v-if="show">
      <layout-header></layout-header>
      <layout-footer></layout-footer>
    </div>
  </div>
</template>
<script>
import layoutHeader from '@/components/layout/layout-header'
import layoutBody from '@/components/layout/layout-body'
import layoutFooter from '@/components/layout/layout-footer'
import Loading from '@/components/loading'
export default {
  name: 'app',
  data() {
    return {
      show: true
    }
  },
  components: {
    layoutHeader,
    layoutBody,
    layoutFooter,
    Loading
  },
  watch: {
    $route: {
      handler: function (val, oldVal) {
        if (val.matched[0].meta.title == '文章管理' || val.matched[0].meta.title == '管理员登录') {
          this.show = false
        } else {
          this.show = true
        }
      },
      // 深度观察监听
      deep: true
    }
  }
}
</script>
<style lang="scss">
@import './assets/scss/base-layout';
#app {
  font-size: 17px;
  color: #6f6f6f;
}
</style>
