import store from "@/store";
import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/Home.vue"),
    meta: { title: "首页" },
  },
  {
    path: "/category/:cate",
    name: "category",
    component: () => import("../views/Home.vue"),
    meta: { title: "分类", params: "cate" },
  },
  {
    path: "/search/:words",
    name: "search",
    component: () => import("../views/Home.vue"),
    meta: { title: "搜索", params: "words" },
  },
  {
    path: "/all",
    name: "all",
    component: () => import("../views/Home.vue"),
    meta: { title: "全部文章" },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/About.vue"),
    meta: { title: "关于" },
  },
  {
    path: "/article/:id",
    name: "article",
    component: () => import("../views/Articles.vue"),
    meta: { title: "文章" },
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return {
        x: 0,
        y: 0,
      };
    }
  },
});
router.beforeEach((to, from, next) => {
  let title = "Gblog";
  if (to.meta.params) {
    title = `${to.meta.title}:${to.params[to.meta.params] || ""} - ${title}`;
  } else {
    title = `${to.meta.title} - ${title}`;
  }
  document.title = title;
  if (to.path !== from.path && to.path.indexOf("/admin")) {
    store.dispatch("setLoading", true);
  }
  next();
});
router.afterEach((to, from) => {
  setTimeout(() => {
    store.dispatch("setLoading", false);
  }, 500);
});
export default router;
