import axios from 'axios'
import qs from 'qs'
var baseUrl
console.log(process.env.VUE_APP_BASE_URL)
console.log(process.env.VUE_APP_API_ENV)
console.log(process.env.NODE_ENV)
if (process.env.NODE_ENV === 'production') {
    baseUrl = 'https://www.rosehistory.top/api/'// 开发环境dev ~
} else {
    baseUrl = 'http://localhost:8080/'// 开发环境dev ~
}
let http = axios.create({
    baseURL: baseUrl,
    timeout: 20000, // 请求超时时间
})
// axios拦截器   在发送请求前经过
http.interceptors.request.use(config => {
    return config
}, function (error) {
    // 对错误的请求信息做点什么
    return Promise.reject(error);
})
// axios响应器  接收到数据时会触发
http.interceptors.response.use(
    response => {  //成功请求到数据
        let rawData = null
        if (response.status !== 200) {
            rawData = { code: 1, err: response.data, msg: '请求失败' }
        } else {
            rawData = response.data
        }
        // console.log(rawData)
        // 如果返回过来的数据status!=200 那么让返回的值code改成1 msg改成失败返回给调用接口的时候
        if (typeof rawData !== 'object') {
            // rawData.isSuccess = false
            // rawData.msg = '数据解析失败'
        } else {
            rawData.isSuccess = rawData.code === 1
        }
        // 如果rawData不是对象，那么msg改成数据失败，如果是 isSuccess=true
        return rawData
    })
// 封装axios请求
class API {
    static get(api, params = {}) {
        return http.get(api, {
            params: Object.assign(params, { t: new Date().getTime() }),
        })
    }
    static post(api, params = {}) {
        return http.post(api, qs.stringify(params), {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded'
            }
        })
    }
    static getArticle(data) {
        return API.get('/mode', data)
    }
    static getArticleDetail(data) {
        return API.get('/mode/' + data.id)
    }
    static setUsrPosition(data) {
        return API.post('/mode/contact', data)
    }
}
export default {
    install(Vue) {
        Vue.prototype.$http = API
    }
}
// main.js引入 import http from "./utils/http";
// Vue.use(http)
//调用 this.$http.demo({})
