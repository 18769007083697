const Mock = require('mockjs');
export default [
    // 站点信息
    {
        url: '/site',
        type: 'get',
        response: () => {
            return {
                code: 20000,
                data: {
                    avatar: '../assets/img/photo.jpg',
                    slogan: 'The way up is not crowded, and most chose ease.',
                    name: 'FZY′blog',
                    domain: 'https://www.baidu.com',
                    notice: '向上的路并不拥挤，大多数人选择了安逸',
                    desc: '一个It技术的探索者'
                }
            }
        }
    },
    // 站点社交信息
    {
        url: '/social',
        type: 'get',
        response: () => {
            return {
                code: 20000,
                data: [

                    {
                        id: 2,
                        title: 'Gitee',
                        icon: 'icongitee',
                        color: '#d81e06',
                        href: 'https://gitee.com/ericptt'
                    },


                ]
            }
        }
    }
]